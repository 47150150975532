<template>
    <div class="sign">
        <div class="left_area">
            <div class="logo">
                <img :src="env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo2.svg') : require('@/assets/images/logo2.png')">
            </div>
            <h2>New Password</h2>
            <Form @submit="handleReset" autocomplete="off" :validation-schema="schema" v-slot="{ errors }">
                <Field autocomplete="off" name="token" v-model="token" hidden />
                <div class="group_item">
                    <label class="input_label">E-mail</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.email }">
                        <Field autocomplete="off" name="email" type="email" placeholder="example@onboardme.io" />
                    </div>
                    <ErrorMessage name="email" class="text-danger" />
                </div>
                <div class="group_item">
                    <label class="input_label">Password</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.password }">
                        <Field autocomplete="off" name="password" type="password" placeholder="6 + strong character" />
                    </div>
                    <ErrorMessage name="password" class="text-danger" />
                </div>
                <div class="group_item">
                    <label class="input_label">Confirm Password</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.password_confirmation }">
                        <Field autocomplete="off" name="password_confirmation" type="password" placeholder="6 + strong character" />
                    </div>
                    <ErrorMessage name="password_confirmation" class="text-danger" />
                </div>
                <button class="primary_btn" :disabled="formLoader">
                    <span v-if="formLoader"><i class="fa fa-spin fa-spinner"></i> Updating</span>
                    <span v-else>Update Password</span>
                </button>
            </Form>
            <div class="forgot">
                <router-link to="/login">Login</router-link>
            </div>
        </div>
        <div class="right_area">
            <img :src="require('@/assets/images/help-center.svg')">
            <h5>Hey, Coach</h5>
            <p>Updating your password is easy. Enter your account email and your new password</p>
            <a :href="env.VUE_APP_DOMAIN_URL ? env.VUE_APP_DOMAIN_URL : 'https://onboardme.io'">{{ env.VUE_APP_DOMAIN_URL ? getDomain(env.VUE_APP_DOMAIN_URL) : 'onboardme.io' }}</a>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import logoImage from '@/assets/images/logo2.png'
    import helpCenterImage from '@/assets/images/help-center.svg'
    import * as yup from 'yup'

    export default {
        name: "Reset",

        data () {
            const schema = yup.object({
                email: yup.string().required().email().label('The email'),
                password: yup.string().required().min(8).label('The password'),
                password_confirmation: yup.string().required().oneOf([yup.ref('password'), null], 'The password confirmation does not match.').label('The password confirmation'),
            });

            return {
                env: {},
                schema,
                token: '',
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            formLoader: state => state.authModule.formLoader,
        }),

        mounted () {
            const vm = this;

            vm.env = process.env;
            vm.token = vm.$route.params.token;
        },

        methods: {
            ...mapActions({
                reset: 'authModule/reset',
            }),

            handleReset (params, { setFieldError }) {
                const vm = this;
                params.setFieldError = setFieldError;

                vm.reset(params);
            },

            getDomain (url) {
                const link = new URL(url);

                return link.hostname;
            },
        },
    }
</script>
